import { User } from '~/data/types/user';

export enum State {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export interface AccountUser extends User {
  userAccountState: State;
}

export interface Account {
  id: string;
  state: State;
  subaccounts: SubAccount[];
  users?: Partial<AccountUser>[];
}

export interface SubAccount {
  externalId?: string;
  id: string;
  legalName?: string;
  subaccountState: State;
}
