/* eslint-disable import/no-deprecated */

import { groupEnd, groupStart } from '@nydig/sweater-vest';
import { negate } from 'lodash';
import { lazy, PropsWithChildren, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import * as ReactRouter from 'react-router-dom';
import { Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { LoadingOverlay } from '~/components/loading-overlay';
import { Redirect } from '~/components/redirect';
import {
  PATH_LEGACY_ACCOUNT_DETAIL,
  PATH_LEGACY_ACCOUNT_DETAIL_ADDRESSES_DEPOSIT,
  PATH_LEGACY_ACCOUNT_DETAIL_ADDRESSES_WITHDRAWAL,
  PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_INVOICES,
  PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_STATEMENTS,
  PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_TRADE_CONFIRMATIONS,
  PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_TRANSACTION_CONFIRMATIONS,
  PATH_LEGACY_ACCOUNT_DETAIL_POSITIONS,
  PATH_LEGACY_ACCOUNT_DETAIL_TRANSACTIONS,
  PATH_LEGACY_MAPPING
} from '~/constants/legacy-paths';
import {
  PATH_ACCOUNT_ADDRESSES_DEPOSIT,
  PATH_ACCOUNT_ADDRESSES_WITHDRAWAL,
  PATH_ACCOUNT_DOCUMENTS_INVOICES,
  PATH_ACCOUNT_DOCUMENTS_STATEMENTS,
  PATH_ACCOUNT_DOCUMENTS_TRADE_CONFIRMATIONS,
  PATH_ACCOUNT_DOCUMENTS_TRANSACTION_CONFIRMATIONS,
  PATH_ACCOUNT_OVERVIEW,
  PATH_ACCOUNT_PINS,
  PATH_ACCOUNT_POSITIONS,
  PATH_ACCOUNT_TRANSACTIONS,
  PATH_ACCOUNTS,
  PATH_AUTHENTICATION__SIGN_IN,
  PATH_CONTACT_US,
  PATH_NOT_FOUND,
  PATH_PRIVACY_NOTICE,
  PATH_TERMS_AND_CONDITIONS,
  PATH_USER__PROFILE,
  PortfolioIdParam
} from '~/constants/paths';
import { useAccountContext } from '~/contexts/Account';
import { LocationState, useAuthenticationContext } from '~/contexts/Authentication';
import { useUserContext } from '~/contexts/User';
import { Account, State } from '~/data/types/account';
import { usePortfoliosQuery } from '~/data/use-portfolio';
import { Location } from '~/hooks/use-history';
import { AccountLayout } from '~/layouts/AccountLayout';
import { DefaultLayout } from '~/layouts/DefaultLayout';
import { EmptyLayout } from '~/layouts/EmptyLayout';

const AccountOverview = lazy(
  () => import(/* webpackChunkName: "account-overview" */ './AccountOverview')
);
const AccountAddressesDeposit = lazy(
  () => import(/* webpackChunkName: "account-addresses-deposit" */ './AccountAddressesDeposit')
);
const AccountAddressesWithdrawal = lazy(
  () =>
    import(/* webpackChunkName: "account-addresses-withdrawal" */ './AccountAddressesWithdrawal')
);
const AccountDocumentsInvoices = lazy(
  () => import(/* webpackChunkName: "account-documents-invoices" */ './AccountDocumentsInvoices')
);
const AccountDocumentsStatements = lazy(
  () =>
    import(/* webpackChunkName: "account-documents-statements" */ './AccountDocumentsStatements')
);
const AccountDocumentsTradeConfirmations = lazy(
  () =>
    import(
      /* webpackChunkName: "account-documents-trade-confirmations" */ './AccountDocumentsTradeConfirmations'
    )
);
const AccountDocumentsTransactionConfirmations = lazy(
  () =>
    import(
      /* webpackChunkName: "account-documents-transaction-confirmations" */ './AccountDocumentsTransactionConfirmations'
    )
);
const AccountPositions = lazy(
  () => import(/* webpackChunkName: "account-positions" */ './AccountPositions')
);
const AccountTransactions = lazy(
  () => import(/* webpackChunkName: "account-transactions" */ './AccountTransactions')
);

const AuthenticationSignIn = lazy(
  () => import(/* webpackChunkName: "authentication-sign-in" */ './AuthenticationSignIn')
);

const Accounts = lazy(() => import(/* webpackChunkName: "accounts" */ './Accounts'));
const ContactUs = lazy(() => import(/* webpackChunkName: "contact-us" */ './ContactUs'));
const NotFound = lazy(() => import(/* webpackChunkName: "not-found" */ './NotFound'));
const PrivacyNotice = lazy(
  () => import(/* webpackChunkName: "privacy-notice" */ './PrivacyNotice')
);
const TermsAndConditions = lazy(
  () => import(/* webpackChunkName: "terms-and-conditions" */ './TermsAndConditions')
);

const UserProfile = lazy(() => import(/* webpackChunkName: "user-profile" */ './UserProfile'));

export interface TitleProps {
  title: string;
  titleTemplate?: (title: string) => string;
}

const Title = ({ title, titleTemplate }: TitleProps) => {
  const defaultTitle = 'NYDIG';
  const value = useTitle(title, titleTemplate);

  return (
    <Helmet defaultTitle={defaultTitle} titleTemplate={`%s | ${defaultTitle}`}>
      <title>{value}</title>
    </Helmet>
  );
};

const useTitle = (title: string, titleTemplate?: (title: string) => string): string => {
  return titleTemplate ? titleTemplate(title) : title;
};
const useAccountTitle = (title: string): string => {
  const { subaccount } = useAccountContext();

  return `${title}  | Account ${subaccount.externalId}`;
};

interface RouteChildrenProps extends Exclude<ReactRouter.RouteChildrenProps, 'location'> {
  conditions?: RouteCondition[];
  to?: Location;
}

const RouteChildren = ({
  children,
  conditions = [],
  location,
  to = PATH_ACCOUNTS
}: PropsWithChildren<RouteChildrenProps>) => {
  const {
    state: { isAuthenticated }
  } = useAuthenticationContext();
  const isAuthorized = useCheckRouteConditions(conditions);

  if (isAuthorized) {
    return <>{children}</>;
  }

  if (isAuthenticated) {
    return <Redirect push={false} to={(location.state as LocationState)?.from ?? to} />;
  }

  return (
    <Redirect
      to={{
        path: PATH_AUTHENTICATION__SIGN_IN,
        state: {
          from: location,
          reason: 'UNAUTHORIZED'
        }
      }}
    />
  );
};

interface RouteProps
  extends Pick<ReactRouter.RouteProps, 'children' | 'exact' | 'location' | 'sensitive' | 'strict'> {
  conditions?: RouteCondition[];
  path: string[] | string;
  title?: string;
  titleTemplate?: (title: string) => string;
  to?: Location;
}

const Route = ({
  children,
  conditions = [],
  exact,
  path,
  title,
  titleTemplate,
  to
}: RouteProps) => {
  return (
    <ReactRouter.Route exact={exact} path={path}>
      {(props: RouteChildrenProps) => {
        return (
          <>
            {title && <Title title={title} titleTemplate={titleTemplate} />}
            {conditions ? (
              <RouteChildren {...props} conditions={conditions} key={path.toString()} to={to}>
                {children}
              </RouteChildren>
            ) : (
              children
            )}
          </>
        );
      }}
    </ReactRouter.Route>
  );
};

const LegacyPathRedirect = () => {
  const {
    params: { externalId },
    path
  } = useRouteMatch<{
    externalId: string;
  }>();
  const { data: accounts = [], isLoading } = usePortfoliosQuery();

  if (isLoading) {
    return <LoadingOverlay />;
  }

  const account = accounts.find(
    ({ subaccounts }) => subaccounts[0].externalId === externalId
  ) as Account;
  let to: Location = PATH_ACCOUNTS;

  if (account) {
    to = {
      path: {
        params: {
          portfolioId: account.id
        },
        pathname: PATH_LEGACY_MAPPING[path]
      }
    };
  }

  return <Redirect to={to} />;
};

interface RouteConditionArgs {
  match: ReactRouter.match;
}

type RouteCondition = (args: RouteConditionArgs) => boolean;

const useCheckRouteConditions = (conditions: RouteCondition[] = []): boolean => {
  const match = useRouteMatch();
  const args: RouteConditionArgs = {
    match
  };

  try {
    groupStart('useCheckRouteConditions');

    // We map then check all values are truthy to ensure all hooks run vs. short-circuiting
    // on first falsy value which potentially could violate the rule of hooks.
    return conditions.map((condition) => condition(args)).every(Boolean);
  } finally {
    groupEnd();
  }
};

const useIsAuthenticatedCondition: RouteCondition = (_args: RouteConditionArgs): boolean => {
  const {
    state: { isAuthenticated }
  } = useAuthenticationContext();
  const result = isAuthenticated;

  return result;
};
const useIsValidAccountCondition: RouteCondition = ({
  match: { params }
}: RouteConditionArgs): boolean => {
  const { portfolioId } = params as PortfolioIdParam;
  const {
    state: { isAdmin }
  } = useAuthenticationContext();
  const { accounts } = useUserContext();

  let result;

  if (isAdmin) {
    result = true;
  } else {
    result = accounts.some((account) => account.id === portfolioId);
  }

  return result;
};
const useIsAccountActiveCondition: RouteCondition = (_args: RouteConditionArgs): boolean => {
  const { state } = useAccountContext();
  const {
    state: { isAdmin }
  } = useAuthenticationContext();
  const result = state === State.Active || isAdmin;

  return result;
};
const useIsValidAccountUserCondition: RouteCondition = (_args: RouteConditionArgs): boolean => {
  const { users } = useAccountContext();
  const {
    state: { isAdmin }
  } = useAuthenticationContext();
  const { id } = useUserContext();
  let result;

  if (isAdmin) {
    result = true;
  } else {
    result = users
      ? users.some((user) => user.id === id && user.userAccountState === State.Active)
      : false;
  }

  return result;
};

export const usePage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

const Pages = () => {
  usePage();

  return (
    <Switch>
      <Route conditions={[useIsAuthenticatedCondition]} exact path={PATH_ACCOUNTS} title="Accounts">
        <DefaultLayout>
          <Accounts />
        </DefaultLayout>
      </Route>

      <Route
        conditions={[useIsAuthenticatedCondition, useIsValidAccountCondition]}
        exact
        path={[
          PATH_ACCOUNT_OVERVIEW,
          PATH_ACCOUNT_ADDRESSES_DEPOSIT,
          PATH_ACCOUNT_ADDRESSES_WITHDRAWAL,
          PATH_ACCOUNT_DOCUMENTS_INVOICES,
          PATH_ACCOUNT_DOCUMENTS_STATEMENTS,
          PATH_ACCOUNT_DOCUMENTS_TRADE_CONFIRMATIONS,
          PATH_ACCOUNT_DOCUMENTS_TRANSACTION_CONFIRMATIONS,
          PATH_ACCOUNT_PINS,
          PATH_ACCOUNT_POSITIONS,
          PATH_ACCOUNT_TRANSACTIONS
        ]}
      >
        <AccountLayout>
          <Switch>
            <Route
              conditions={[useIsAccountActiveCondition, useIsValidAccountUserCondition]}
              exact
              path={PATH_ACCOUNT_OVERVIEW}
              title="Overview"
              titleTemplate={useAccountTitle}
            >
              <AccountOverview />
            </Route>
            <Route
              conditions={[useIsAccountActiveCondition, useIsValidAccountUserCondition]}
              exact
              path={PATH_ACCOUNT_ADDRESSES_DEPOSIT}
              title="Deposit Addresses"
              titleTemplate={useAccountTitle}
            >
              <AccountAddressesDeposit />
            </Route>
            <Route
              conditions={[useIsAccountActiveCondition, useIsValidAccountUserCondition]}
              exact
              path={PATH_ACCOUNT_ADDRESSES_WITHDRAWAL}
              title="Withdrawal Addresses"
              titleTemplate={useAccountTitle}
            >
              <AccountAddressesWithdrawal />
            </Route>
            <Route
              conditions={[useIsAccountActiveCondition, useIsValidAccountUserCondition]}
              exact
              path={PATH_ACCOUNT_DOCUMENTS_INVOICES}
              title="Invoices | Documents"
              titleTemplate={useAccountTitle}
            >
              <AccountDocumentsInvoices />
            </Route>
            <Route
              conditions={[useIsAccountActiveCondition, useIsValidAccountUserCondition]}
              exact
              path={PATH_ACCOUNT_DOCUMENTS_STATEMENTS}
              title="Statements | Documents"
              titleTemplate={useAccountTitle}
            >
              <AccountDocumentsStatements />
            </Route>
            <Route
              conditions={[useIsAccountActiveCondition, useIsValidAccountUserCondition]}
              exact
              path={PATH_ACCOUNT_DOCUMENTS_TRADE_CONFIRMATIONS}
              title="Trade Confirmations | Documents"
              titleTemplate={useAccountTitle}
            >
              <AccountDocumentsTradeConfirmations />
            </Route>
            <Route
              conditions={[useIsAccountActiveCondition, useIsValidAccountUserCondition]}
              exact
              path={PATH_ACCOUNT_DOCUMENTS_TRANSACTION_CONFIRMATIONS}
              title="Transaction Confirmations | Documents"
              titleTemplate={useAccountTitle}
            >
              <AccountDocumentsTransactionConfirmations />
            </Route>
            <Route
              conditions={[useIsAccountActiveCondition, useIsValidAccountUserCondition]}
              exact
              path={PATH_ACCOUNT_POSITIONS}
              title="Positions"
              titleTemplate={useAccountTitle}
            >
              <AccountPositions />
            </Route>
            <Route
              conditions={[useIsAccountActiveCondition, useIsValidAccountUserCondition]}
              exact
              path={PATH_ACCOUNT_TRANSACTIONS}
              title="Transactions"
              titleTemplate={useAccountTitle}
            >
              <AccountTransactions />
            </Route>
          </Switch>
        </AccountLayout>
      </Route>

      <Route
        conditions={[useIsAuthenticatedCondition]}
        exact
        path={[
          PATH_LEGACY_ACCOUNT_DETAIL,
          PATH_LEGACY_ACCOUNT_DETAIL_ADDRESSES_DEPOSIT,
          PATH_LEGACY_ACCOUNT_DETAIL_ADDRESSES_WITHDRAWAL,
          PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_INVOICES,
          PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_STATEMENTS,
          PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_TRADE_CONFIRMATIONS,
          PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_TRANSACTION_CONFIRMATIONS,
          PATH_LEGACY_ACCOUNT_DETAIL_POSITIONS,
          PATH_LEGACY_ACCOUNT_DETAIL_TRANSACTIONS
        ]}
        title="Redirecting..."
      >
        <LegacyPathRedirect />
      </Route>

      <Route
        conditions={[useIsAuthenticatedCondition]}
        exact
        path={PATH_USER__PROFILE}
        title="Profile | User"
      >
        <DefaultLayout>
          <UserProfile />
        </DefaultLayout>
      </Route>

      <Route
        conditions={[negate(useIsAuthenticatedCondition)]}
        path={PATH_AUTHENTICATION__SIGN_IN}
        title="Sign In | Authentication"
      >
        <EmptyLayout>
          <AuthenticationSignIn />
        </EmptyLayout>
      </Route>

      <Route
        conditions={[useIsAuthenticatedCondition]}
        exact
        path={PATH_CONTACT_US}
        title="Contact Us"
      >
        <DefaultLayout>
          <ContactUs />
        </DefaultLayout>
      </Route>

      <Route exact path={PATH_PRIVACY_NOTICE} title="Privacy Notice">
        <DefaultLayout>
          <PrivacyNotice />
        </DefaultLayout>
      </Route>

      <Route exact path={PATH_TERMS_AND_CONDITIONS} title="Terms and Conditions">
        <DefaultLayout>
          <TermsAndConditions />
        </DefaultLayout>
      </Route>

      <Route path={PATH_NOT_FOUND} title="Not Found">
        <DefaultLayout>
          <NotFound />
        </DefaultLayout>
      </Route>
    </Switch>
  );
};

export default Pages;
