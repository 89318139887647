export interface PortfolioIdParam {
  portfolioId: string;
}

const PORTFOLIO_ID_PARAM = ':portfolioId(por-[0-7][0-9A-HJKMNP-TV-Z]{25})';

//
// GLOBAL
//

export const PATH_ACCOUNTS = '/';
export const PATH_CONTACT_US = '/contact-us';
export const PATH_NOT_FOUND = '*';
export const PATH_PRIVACY_NOTICE = '/privacy-notice';
export const PATH_TERMS_AND_CONDITIONS = '/terms-and-conditions';

//
// ACCOUNT
//

const PATH_ACCOUNT = `/account/${PORTFOLIO_ID_PARAM}`;
export const PATH_ACCOUNT_OVERVIEW = `${PATH_ACCOUNT}/overview`;
export const PATH_ACCOUNT_PINS = `${PATH_ACCOUNT}/pins`;
export const PATH_ACCOUNT_ADDRESSES = `${PATH_ACCOUNT}/addresses`;
export const PATH_ACCOUNT_ADDRESSES_DEPOSIT = `${PATH_ACCOUNT_ADDRESSES}/deposit`;
export const PATH_ACCOUNT_ADDRESSES_WITHDRAWAL = `${PATH_ACCOUNT_ADDRESSES}/withdrawal`;
export const PATH_ACCOUNT_DOCUMENTS = `${PATH_ACCOUNT}/documents`;
export const PATH_ACCOUNT_DOCUMENTS_INVOICES = `${PATH_ACCOUNT_DOCUMENTS}/invoices`;
export const PATH_ACCOUNT_DOCUMENTS_STATEMENTS = `${PATH_ACCOUNT_DOCUMENTS}/statements`;
export const PATH_ACCOUNT_DOCUMENTS_TRADE_CONFIRMATIONS = `${PATH_ACCOUNT_DOCUMENTS}/trade-confirmations`;
export const PATH_ACCOUNT_DOCUMENTS_TRANSACTION_CONFIRMATIONS = `${PATH_ACCOUNT_DOCUMENTS}/transaction-confirmations`;
export const PATH_ACCOUNT_POSITIONS = `${PATH_ACCOUNT}/positions`;
export const PATH_ACCOUNT_TRANSACTIONS = `${PATH_ACCOUNT}/transactions`;

//
// AUTHENTICATION
//

export const PATH_AUTHENTICATION__SIGN_IN = '/signin'; // Must be `signin` to match OktaSignIn router

//
// USER - PROFILE
//

export const PATH_USER__PROFILE = '/user/profile';
