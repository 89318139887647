import { FunctionComponent, memo, NamedExoticComponent, PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import { Footer } from '~/components/footer';
import { HeaderAccount } from '~/components/HeaderAccount';
import { PortfolioIdParam } from '~/constants/paths';
import { AccountContextProvider } from '~/contexts/Account';
import { EmptyLayout } from '~/layouts/EmptyLayout';

type MemorizedAccountLayoutProps = {
  portfolioId: string;
};

const MemorizedAccountLayout: NamedExoticComponent<PropsWithChildren<MemorizedAccountLayoutProps>> =
  memo(
    function MemorizedAccountLayout({
      children,
      portfolioId
    }: PropsWithChildren<MemorizedAccountLayoutProps>) {
      return (
        <AccountContextProvider portfolioId={portfolioId}>
          <div className="flex flex-col min-h-screen">
            <HeaderAccount />
            <EmptyLayout>{children}</EmptyLayout>
            <Footer fixed />
          </div>
        </AccountContextProvider>
      );
    },
    (prevProps, nextProps): boolean => {
      return prevProps.portfolioId === nextProps.portfolioId;
    }
  );

export const AccountLayout: FunctionComponent = ({ children }) => {
  const { portfolioId } = useParams<PortfolioIdParam>();

  return <MemorizedAccountLayout portfolioId={portfolioId}>{children}</MemorizedAccountLayout>;
};
